.Loading-header {
    background-color: rgb(173, 216, 230);
    background-image: url('../../assets/img/bg/Ts.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }